import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import API_CONST from '@/constants/ApiConst';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import dashboardStore from '@/store/modules/dashboardStore';
import programConfigureStore from '@/store/modules/programConfigure';
import commonEntry from '@/store/modules/commonEntry';
import { getRetention, fetchLMSUrl, getAda } from '@/services/dashboard/dashboardService';
import { getSiteByProgramId } from '@/services/create-user/createUserService';
import { updateTaskStatus } from '@/services/program/plannerService';
import programListStore from '@/store/modules/programList';
import moment from 'moment';
import { getAdaProgramData, getRetentionProgramData } from '@/services/analytics/analyticsService';

@Component
export default class AdminDashboardComponent extends Vue {
  private objScreenText: ScreenText = new ScreenText();
  readonly STYLE = APP_CONST.STYLE;
  public url = API_CONST.THOUGHTINDUSTRYURL;
  public label_text = ['Dashboard', 'Scholar Management - Recruitment, Enrollment and Attendance'];
  public navigatePlannerPage: boolean = false;
  public weekDates: any = [];
  public tempDs: any = {};
  public taskEventDS: any = {};
  public userRoles: any = [];
  public programId: number = 0;
  public currentDate = APP_UTILITIES.getCurrentDate();
  public taskEventList: any = [];
  public totalTaskCount: number = 0;
  public totalEvntCount: number = 0;
  public todayTask: any = [];
  public upComingTask: any = [];
  public activeDay: any = 0;
  public currentSiteId: any = 0;
  public currentRoleId: any = 0;
  public isMounted: string = '';
  public responseLength: number = -1;
  public currentUserRoleId: number = 0;
  public adaPercentage: any = '';
  public retPercentage: any = '';
  public proLearningUrl: string = '';
  public siteName: string = '';
  public errorResponse: Object = {};
  public proLearningId: number = 1;
  public hovering: boolean = false;
  public oldUserObj: any = { roleId: 0 };
  public currentUserObj: any;
  public hover: boolean = false;
  public hoverText: string = '';
  public hoverLabel: string = '';
  public hoverStyleObj: any = {};
  public firstNameTooltip: boolean = false;
  public programNameTooltip: boolean = false;
  public eventsTooltip: boolean = false;
  public getTasksStatusList: any = APP_CONST.GET_TASK_STATUSES;
  public dropDownTaskId: number = -1;
  public openDropTaskStatus: boolean = false;
  public selectedTasksStatus: any = { index: 0, label: 'ACTIVE', path: 'planner-status-profile.svg', value: '1' };
  public status: string[] = ['Active', 'Done', 'Skip'];
  public selectedTaskId: number = -1;
  public showKebabMenu: boolean = false;
  public currentSelectedDay: string = '';
  public bGetSiteName: boolean = true;
  public isMobile: boolean = APP_UTILITIES.checkMobile();
  public program: any = {};
  public retensionPercentage: number | string = 'N/A';
  public adaPercent: number | string = 'N/A';
  public showProffessionalLearning: boolean = false;

  @Prop()
  userDetails!: any;

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  @Watch('userDetails', { immediate: true, deep: true })
  updateData(data: {}) {
    this.currentUserObj = data;
    this.getSiteId();
    this.getWeekDays();
    this.getCondenseTastEvent();
    this.dashboardData();
    this.oldUserObj = data;
  }

  get role() {
    const roleId = (this.drilledProgram && this.drilledProgram.roleId) || 0;
    let backGroundClass = '';
    if (roleId > APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN) {
      backGroundClass = roleId === 5
        ? 'prog-banner'
        : 'site-banner';
    }
    return { roleId, backGroundClass };
  }

  /* istanbul ignore next */
  get lxEnabled() {
    return programListStore.appSetting;
  }

  @Watch('lxEnabled', { immediate: true, deep: true })
  cmsFeatureFlag(store: any) {
    const highestRole = Number(APP_UTILITIES.getCookie('highest_role'));
    const isBxl = highestRole !== null && highestRole < APP_CONST.FOUR;
    if (isBxl) {
      return true;
    }
    else {
      const { isLoading, lx } = store;
      if (isLoading) {
        //Do Nothing
      }
      else {
        Vue.set(this, 'showProffessionalLearning', !lx);
      }
    }
  }

  /* istanbul ignore next */
  get programsList() {
    return programListStore.programListFilters;
  }

  /* istanbul ignore next */
  get isProfessionalLearning() {
    let isProfessionalLearning = true;
    const { siteId, programId } = APP_UTILITIES.coreids();
    if (programId) {
      this.programsList && this.programsList.length && this.programsList.forEach((element: any) => {
        if (element && element.programId == programId) {
          this.program = element;
        }
      });
      if (siteId && this.program.status == 2) {
        isProfessionalLearning = false;
      }
      else {
        isProfessionalLearning = true;
      }
    }
    return isProfessionalLearning;
  }

  get drilledProgram() {
    return programListStore.drilledProgram;
  }

  navigate(route: string) {
    if (route == 'retention') {
      this.$router.push('/analytics/scholar/retention');
    }
    else if (route == 'attendance') {
      this.$router.push('/analytics/scholar/attendance/ada');
    }
    else {
      this.$router.push('/analytics/scholar');
    }
  }

  checkForRole(userRoles: any, finalSubmit: boolean) {
    let index = 0;
    userRoles.forEach((item: any, id: number) => {
      if (item.hasOwnProperty('programId')) {
        index = id;
      }
    });
    const roleId = userRoles[index].roleId;
    const checkForConfigureRoute = ((roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID || roleId === APP_CONST.PROGRAM_ADMIN_ROLE_ID || roleId === APP_CONST.SESSION_ADMIN_ROLE_ID) && !finalSubmit);
    if (checkForConfigureRoute) {
      this.$router.push('/configureprogram');
    }
    else if (roleId > 1 && !checkForConfigureRoute) {
      this.$router.push('/home');
    }
    else {
      this.$router.push('/');
    }
  }

  public getProgramId() {
    let pID = 0;
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    if (!this.userRoles) {
      return; 
    }
    this.userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('programId')) {
        pID = item.programId;
      }
      else {
        const programId: any = APP_UTILITIES.getCookie('programId');
        pID = Number(programId);
      }
      /* istanbul ignore else */
      if (item.hasOwnProperty('roleId')) {
        this.currentRoleId = item.roleId;
      }
    });
    this.programId = pID;
    return this.programId;
  }

  public convertDateInGMT(date: string) {
    return APP_UTILITIES.convertDateInGMT(date);
  }

  public dayFormat(date: string) {
    return APP_UTILITIES.dayFormat(date);
  }

  public monthFormat(date: string) {
    return APP_UTILITIES.monthFormat(date);
  }

  public goToPlannerPage() {
    this.$router.push('/planner');
  }

  public formatDate(date: string) {
    return APP_UTILITIES.formatDate(date, true);
  }
  public get12hrFormateTimeFromDate(date: string) {
    return APP_UTILITIES.convert12hrFormateTimeFromDate(date);
  }
  getSiteName() {
    const site: string | null = APP_UTILITIES.getCookie('siteName');
    /* istanbul ignore else */
    if (site && JSON.parse(site) && this.bGetSiteName) {
      const dlObject = { 'SiteName': JSON.parse(site) };
      this.$emit('updateDataLayer', dlObject);
      this.bGetSiteName = false;
      return JSON.parse(site);
    }
    return '';
  }

  async setAccountData() {
    const accId = APP_UTILITIES.getAccountIdByCookies();
    const payload = { accountId: accId[0] };
    await programListStore.accountById(payload);
    /* istanbul ignore else */
    if (programListStore.accountDetails.status === APP_CONST.RESPONSE_200) {
      const accountDt = { ...programListStore.accountDetails.data };
      let dlUserRole: any = null;
      let dlRole = null;
      const dlUserObjExists = APP_UTILITIES.checkCookie('dlUserRole');
      if (dlUserObjExists) {
        dlUserRole = APP_UTILITIES.getCookie('dlUserRole');
        dlRole = JSON.parse(dlUserRole);
      }
      const checkDlRole = (!dlRole)
        ? this.userDetails.roleId
        : dlRole;
      const dlObject = { 'AccountName': accountDt.accountName, 'ProgramName': this.userDetails.progName, 'AccountType': accountDt.type, 'UserRole': checkDlRole };
      this.$emit('updateDataLayer', dlObject);
    }
  }

  mounted() {
    if (this.$route.path === '/home') {
      this.$emit('loadNav');
    }
    this.setActiveDate();
    let dlObject: any = {};
    if (APP_UTILITIES.checkCookie('dlObject')) {
      const dlCookieData: any = APP_UTILITIES.getCookie('dlObject');
      const dlData = JSON.parse(dlCookieData);
      if (dlData) {
        dlObject = { ...dlData };
      }
    }
    /* istanbul ignore else */
    if (this.userDetails.roleId === APP_CONST.FIVE) {
      this.setAccountData();
      dlObject['ProgramName'] = this.userDetails.progName;
      dlObject['SiteName'] = undefined;
      this.$emit('updateDataLayer', dlObject);
    }
    else if (this.userDetails.roleId === APP_CONST.SIX) {
      this.setAccountData();
      dlObject['SiteName'] = this.getSiteName() || this.siteName;
      this.$emit('updateDataLayer', dlObject);
    }
    document.cookie = 'dlObject' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  public setActiveDate() {
    const currDate: any = APP_UTILITIES.getFullDate(this.currentDate);
    const activeDate = 'day' + currDate;
    this.currentSelectedDay = activeDate;

    if (this.currentRoleId == 6) {
      getSiteByProgramId(this.programId).then((res: any) => {
        /* istanbul ignore else */
        if (res.status === APP_CONST.RESPONSE_200) {
          const data = res.data;
          /* istanbul ignore else */
          if (data) {
            const sitesFill: any = [];
            data.forEach((item: any) => {
              sitesFill.push({ name: item.siteName, id: item.siteId });
            });
            const rolewiseSite = sitesFill.find((site: any) => site.id === this.currentSiteId);
            if (rolewiseSite) {
              this.siteName = rolewiseSite.name + '.';
            }
          }
        }
      });
    }
  }


  public getSiteId() {
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    /* istanbul ignore else */
    if (this.userRoles != null) {
      this.currentUserRoleId = this.userRoles[0].roleId;
      this.userRoles.forEach((item: any) => {
        /* istanbul ignore else */
        if (item.hasOwnProperty('siteId')) {
          this.currentSiteId = item.siteId;
        }
        else {
          const siteId: any = APP_UTILITIES.getCookie('siteId');
          this.currentSiteId = Number(siteId);
        }
      });
    }
  }
  public getProgramIdNew(userRoles: any) {
    let pID = 0;
    /* istanbul ignore else */
    if (userRoles != null) {
      userRoles.forEach((item: any, index: number) => {
        if (item.hasOwnProperty('programId')) {
          pID = item.programId;
        }
      });
    }
    return { pID, userRoles };
  }

  public isProgramConfigured(userDt: any) {
    let isFinalSubmit = false;
    const data = this.getProgramIdNew(userDt) as any;
    programConfigureStore.getProgramConfigObjProgramPage(data.pID).then((programData: any) => {
      /* istanbul ignore else */
      if (programData && programData.data) {
        isFinalSubmit = programData.data.isFinalSubmit;
        this.checkForRole(data.userRoles, isFinalSubmit);
      }
    });
  }

  navigateToScreen(): void {
    const userRoles = APP_UTILITIES.getCookie('user_role') as any || {};
    this.isProgramConfigured(JSON.parse(userRoles));
    this.UserDetail();
  }

  public UserDetail() {
    const user_id: any = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
    commonEntry.fetchUserDetails(parseInt(user_id));
  }

  zeroFixed(x: any, d: number) {
    if (!d) {
      return x.toFixed(d);
    }
    return x.toFixed(d).replace(/\.?0+$/, '');
  }
  async dashboardData() {
    this.getProgramId();
    /* istanbul ignore else */
    if (this.programId > 0) {
      this.retensionPercentage = 'N/A';
      this.adaPercent = 'N/A';
      const response = await getAda(this.programId, this.currentSiteId, false);
      /* istanbul ignore else */
      const response1 = await getRetention(this.programId, this.currentSiteId, false);
      /* istanbul ignore else */
      const retPercentageResponse = await getRetentionProgramData(this.programId);
      const adaPercentageResponse = await getAdaProgramData(this.programId);

      this.CheckAdaResponse(response);
      this.CheckRetentionResponse(response1);
      this.CheckAdaPercentageResponse(adaPercentageResponse);
      this.CheckRetentionPercentageResponse(retPercentageResponse);
    }
  }

  private CheckRetentionResponse(response1: { data: any; status: number; statusText: string }) {
    const retPercent = (response1 && response1.data && response1.data.averageResult);
    this.retPercentage = retPercent
      ? this.zeroFixed(retPercent, 1)
      : 'N/A';
  }

  private CheckAdaResponse(response: { data: any; status: number; statusText: string }) {
    const adaPercentage = (response && response.data && response.data.averageResult);
    this.adaPercentage = adaPercentage
      ? this.zeroFixed(adaPercentage, 1)
      : 'N/A';
  }

  private CheckRetentionPercentageResponse(responseRet: { data: any; status: number; statusText: string }) {
    let isNoAttendance: boolean = APP_CONST.FALSE;
    this.retensionPercentage = 'N/A';
    isNoAttendance = Object.keys(responseRet.data.dataSet).every((element: any) => !responseRet.data.dataSet[element].isAttendanceMarked);
    this.retensionPercentage = isNoAttendance
      ? 'N/A'
      : Number(responseRet.data.averagePercentage).toFixed();
  }

  private CheckAdaPercentageResponse(responseAda: { data: any; status: number; statusText: string }) {
    let isNoAttendance: boolean = APP_CONST.FALSE;
    this.adaPercent = 'N/A';
    isNoAttendance = Object.keys(responseAda.data.dataSet).every((element: any) => !responseAda.data.dataSet[element].isAttendanceMarked);
    this.adaPercent = isNoAttendance
      ? 'N/A'
      : Number(responseAda.data.averagePercentage).toFixed();
  }

  beforeMount() {
    this.getSiteId();
    this.getWeekDays();
    this.getCondenseTastEvent();
    this.dashboardData();
  }

  getCondenseTastEvent() {
    let drillDownValue = false;
    const drill = APP_UTILITIES.getCookie('drilldown_key');
    const superDrill = APP_UTILITIES.getCookie('super_user_drilldown');
    if ((drill && (JSON.parse(drill).actor !== JSON.parse(drill).drill)) || (superDrill && JSON.parse(superDrill))) {
      drillDownValue = true;
    }
    const args = {
      programId: (this.getProgramId() === 0)
        ? APP_UTILITIES.getCookie('programId')
        : this.getProgramId(),
      siteId: (this.currentRoleId === 7 || this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0,
      productId: programConfigureStore.productId,
      startDate: this.weekDates[0],
      endDate: this.weekDates[this.weekDates.length - 1],
      isDrillDown: drillDownValue
    };
    dashboardStore.fetchCondenseTaskEvent(args).then((res: any) => {
      this.todayTask = [];
      this.condensePlannerResponse(res);
    });
  }

  condensePlannerResponse(res: any) {
    /* istanbul ignore else */
    if ((res.status === APP_CONST.RESPONSE_200) || (res.status === APP_CONST.RESPONSE_204)) {
      const filteredArray = res.data || [];
      this.responseLength = filteredArray.length;
      const sortedArray = APP_UTILITIES.sortDates(filteredArray);
      this.prepareWeekData(sortedArray);
      this.getCurrDateEvntTaskCount();
      const currDate: any = APP_UTILITIES.getFullDate(this.currentDate);
      const activeDate = 'day' + currDate;
      const activeDay: any = document.getElementById(activeDate);
      if (activeDay) {
        activeDay.click();
      }
    }
  }

  currWeekDays() {
    const weekStart = moment().startOf('week');
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format('YYYY-MM-DD'));
    }
    return days;
  }

  getWeekDays() {
    const weekDays = this.currWeekDays();
    const weekdaysSingleChar = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];
    for (let i = 0; i < weekDays.length; i++) {
      const date = moment(weekDays[i], 'YYYY-MM-DD');
      this.weekDates.push(weekDays[i]);
      const dateKey = moment(weekDays[i]).format('MM/DD/YYYY');
      this.taskEventDS[dateKey] = {
        'task': [],
        'eventCount': 0,
        'taskCount': 0,
        'weekDay': weekdaysSingleChar[i],
        'disPlayDate': date.date(),
        'realDate': weekDays[i],
      };
    }
  }

  public prepareWeekData(sortedArray: any): void {
    for (const taskEv in this.taskEventDS) {
      this.taskEventDS[taskEv].task = [];
      this.taskEventDS[taskEv].eventCount = 0;
      this.taskEventDS[taskEv].taskCount = 0;
      sortedArray.length > 0 && sortedArray.forEach((item: any, index: number) => {
        item.weekDate = this.taskEventDS[taskEv].realDate;
        const taskDate: any = APP_UTILITIES.getFullDate(item.startDate);
        const taskEndDate: any = APP_UTILITIES.getFullDate(item.endDate);
        this.ValidateTaskEvent(taskEv, taskDate, taskEndDate, item);
      });
    }

    const currDate: any = APP_UTILITIES.getFullDate(this.currentDate);
    sortedArray.length > 0 && sortedArray.forEach((item: any, index: number) => {
      const itemStartDate: any = APP_UTILITIES.getFullDate(item.startDate);
      const itemEndDate: any = APP_UTILITIES.getFullDate(item.endDate);
      if (currDate >= itemStartDate && currDate <= itemEndDate && item.status.state == 'Active') {
        this.todayTask.push(item);
      }
    });
  }

  ValidateTaskEvent(taskEv: string, taskDate: any, taskEndDate: any, item: any) {
    if (taskEv >= taskDate && taskEv <= taskEndDate) {
      if (item.isEvent) {
        this.taskEventDS[taskEv].task.push(item);
        this.taskEventDS[taskEv].eventCount += 1;
      }
      else {
        /* istanbul ignore else */
        if (item.status.id === 1) {
          this.taskEventDS[taskEv].task.push(item);
          this.taskEventDS[taskEv].taskCount += 1;
        }
      }
    }
  }

  public getCurrDateEvntTaskCount() {
    for (const taskEv in this.taskEventDS) {
      const taskDate: any = APP_UTILITIES.getFullDate(this.taskEventDS[taskEv].realDate);
      const currDate: any = APP_UTILITIES.getFullDate(this.currentDate);
      if (taskDate === currDate) {
        if (this.taskEventDS[currDate].task.length > 0) {
          this.taskEventDS[currDate].task.forEach((item: any, index: number) => {
            this.totalEvntCount = this.taskEventDS[currDate].eventCount;
            if (item.status.id == 1) {
              this.totalTaskCount = this.taskEventDS[currDate].taskCount;
            }
            else {
              this.totalTaskCount = 0;
            }
          });
        }
        else {
          this.totalEvntCount = 0;
          this.totalTaskCount = 0;
        }
      }

    }
  }
  public openEventTask(item: any) {
    if (item.isEvent) {
      this.$router.push('/programplan?eId=' + item.id + '&type=event');
    }
    else {
      this.$router.push('/programplan?tId=' + item.id + '&type=task');
    }
  }

  public getActiveTask(currDate: string, dateToCompare: string) {
    const d1 = new Date(currDate),
      month1 = '' + (d1.getMonth() + 1),
      day1 = '' + d1.getDate(),
      year1 = d1.getFullYear();
    const d2 = new Date(dateToCompare),
      month2 = '' + (d2.getMonth() + 1),
      day2 = '' + d2.getDate(),
      year2 = d2.getFullYear();
    const dateToCompare1 = [month1, day1, year1,].join('/');
    const dateToCompare2 = [month2, day2, year2,].join('/');
    /* istanbul ignore else */
    if (dateToCompare1 === dateToCompare2) {
      return true;
    }
  }

  public setActiveDay(day: any, currDateKey?: any): void {
    this.currentSelectedDay = day;
    this.todayTask = [];
    this.activeDay = day;
    for (const date in this.taskEventDS) {
      if (date === day) {
        if (this.taskEventDS[day].task.length > 0) {
          this.taskEventDS[day].task.forEach((item: any, index: number) => {
            /* istanbul ignore else */
            const itemStartDate: any = APP_UTILITIES.getFullDate(item.startDate);
            const itemEndDate: any = APP_UTILITIES.getFullDate(item.endDate);
            if (item.status.id == 1) {
              const pushItem = (item.isEvent == true && this.activeDay == itemStartDate && this.activeDay == itemEndDate) ||
                (this.activeDay >= itemStartDate || itemEndDate <= this.activeDay && item.isEvent == false);
              if (pushItem) {
                this.todayTask.push(item);
              }
              this.totalTaskCount = this.taskEventDS[day].taskCount;
              this.totalEvntCount = this.taskEventDS[day].eventCount;
            }
          });
        }
        else {
          this.totalEvntCount = 0;
          this.totalTaskCount = 0;
        }
      }
    }
    if (this.upComingTask.length == 0 && this.todayTask.length == 0) {
      this.responseLength = 0;
    }
    else {
      this.responseLength = this.todayTask.length > 0
        ? this.todayTask.length
        : this.upComingTask.length;
    }
  }

  public markTaskDone(currTask: any, index: number, from: string): any {
    if (from === 'today') {
      if (currTask.status.state === 'Done') {
        return false;
      }
      else if (currTask.status.state === 'Active') {
        currTask.status.id = 2;
        currTask.status.state = 'Done';
        currTask.programId = this.getProgramId();
        dashboardStore.condenseTaskUpdated(currTask).then((res: any) => {
          /* istanbul ignore else */
          if (res && res.status === 200) {
            this.todayTask[index].status.id = 2;
            this.todayTask[index].status.state = 'Done';
          }
        });
      }
    }
    const activeDate = 'day' + this.activeDay;
    const activeDay: any = document.getElementById(activeDate);
    if (activeDay) {
      activeDay.click();
    }
  }

  public navigateProLerningUrl() {
    const newWindow: any = window.open();
    /* istanbul ignore else */
    if (this.proLearningId) {
      fetchLMSUrl(this.proLearningId).then((res) => {
        /* istanbul ignore else */
        if (res.status === 200) {
          newWindow.location = res.data;
        }
      });
    }
  }
  callHover(val: string, $event: any, id: string, isMobileView: string) {
    this.hover = true;
    this.hoverText = val;
    this.hoverLabel = id;
    const classId = '.' + id;
    this.checkDivLength(classId, isMobileView);
    const boundBox = $event && $event.target.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    this.hoverStyleObj = {
      top: (coordY + 40).toString() + 'px',
      left: (coordX + 50).toString() + 'px',
      width: 'fit-content;'
    };
  }
  callHoverOut() {
    this.hover = false;
    this.hoverText = '';
    this.hoverLabel = '';
    this.hoverStyleObj = {};
  }
  checkDivLength(id: string, mobileView: string) {
    const userInformation = <HTMLSpanElement>document.querySelector(id);
    if (userInformation) {
      const computedStyle = window.getComputedStyle(userInformation);
      const nameWidth = computedStyle.width || '';
      const nameWidthInSpliced = nameWidth.slice(0, 6);
      if (mobileView) {
        if (id == '.user-firstName') {
          if (nameWidthInSpliced && parseFloat(nameWidthInSpliced) > 277.23) {
            this.firstNameTooltip = true;
          }
        }
        else if (id == '.user-programName') {
          if (nameWidthInSpliced && parseFloat(nameWidthInSpliced) > 159.35) {
            this.programNameTooltip = true;
          }
        }
      }
      else if (id == '.user-firstName') {
        if (nameWidthInSpliced && parseFloat(nameWidthInSpliced) > 577.56) {
          this.firstNameTooltip = true;
        }
      }
      else if (id == '.user-programName') {
        if (nameWidthInSpliced && parseFloat(nameWidthInSpliced) > 310.533) {
          this.programNameTooltip = true;
        }
      }
    }
  }
  touchStart(val: string, $event: any, id: string) {
    return () => {
      this.callHover(val, $event, id, 'MobileView');
    };
  }
  touchEnd() {
    return () => {
      this.callHoverOut();
    };
  }
  created() {
    document.addEventListener('scroll', () => {
      this.callHoverOut();
    });
  }
  beforeDestroy() {
    document.removeEventListener('scroll', () => {
      this.callHoverOut();
    });
  }

  selectTaskStatus(status: number, index: number) {
    this.selectedTasksStatus = status;
    this.updateTaskStatus({ status: status, taskId: index });
    this.openDropTaskStatus = false;
    this.selectedTaskId = -1;
    setTimeout(() => {
      this.setActiveDay(this.currentSelectedDay);
    }, 2500);
  }
  openDropdown(type: string, index: number, close?: boolean) {
    if (type == 'status-open') {
      this.dropDownTaskId = index;
      this.openDropTaskStatus = this.openDropTaskStatus
        ? APP_CONST.FALSE
        : (close
          ? APP_CONST.FALSE
          : APP_CONST.TRUE);
    }
  }

  updateTaskStatus(taskStatus: { status: number; taskId: number }) {
    const payload = { status: taskStatus.status, id: taskStatus.taskId };
    updateTaskStatus(payload).then((res) => {
      /* istanbul ignore else */
      if (res.status == '200') {
        this.getCondenseTastEvent();
      }
    });
  }

  NavToPlanner(id: number, type: string) {
    this.$router.push({ name: 'Program Planner', params: { id: id.toString(), type } });
  }

  navToAttendance() {
    this.$router.push('/attendance');
  }
}
